import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/util/seo"
import "../assets/scss/layout/pages/404.scss"

const NotFoundPage = ({path}) => (
  <Layout mt={false} path={path}>
    <SEO title="404: Not found" />
    <div className={'error'}>
      <h1>Erreur</h1>
      <p>Cette page n’existe pas, veuillez retourner à la <a href={'/'}>page d’accueil</a>.</p>
    </div>
  </Layout>
)

export default NotFoundPage
